import React, { useEffect } from "react"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const AlpackedPresenation = ({ location }) => {
  useEffect(() => {
    const searchQuery = location.search

    // Define the allowed search queries
    const allowedSearchQueries = [
      "?utm_source=email&utm_medium=PudaQyQYDl8q6MWEnkjXjM95hs3/g/lwVetR0080q8LQVG1FnrTTGyj+d/QBl79D/GOw7gwMrLOKyDSKePDyZfAaXDnvT5MSMWNVKokHRJeNWa/CeGsRiwr5vOXtBAxm",
    ]

    // Check if the current search query is in the allowed search queries
    if (!allowedSearchQueries.includes(searchQuery)) {
      // If not, redirect to the default URL
      navigate(
        "/alpacked_presentation?utm_source=email&utm_medium=PudaQyQYDl8q6MWEnkjXjM95hs3/g/lwVetR0080q8LQVG1FnrTTGyj+d/QBl79D/GOw7gwMrLOKyDSKePDyZfAaXDnvT5MSMWNVKokHRJeNWa/CeGsRiwr5vOXtBAxm"
      )
    }
  }, [location.search])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Alpacked Presentation"
        description="Embark on DevOps and Cloud transformation!"
      />
      <Layout>
        <ContentWrapper>
          <div style={{ marginTop: "10px" }}>
            <iframe
              src="/alpacked_presentation.pdf"
              width="100%"
              height="1000"
              description="Embark on DevOps and Cloud transformation!"
            />
          </div>
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(AlpackedPresenation, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
